import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private profileImage = new BehaviorSubject<string>('/assets/images/user-profile.png');
  profileImage$ = this.profileImage.asObservable();

  storeProfileImage(url: string): void {
    this.profileImage.next(url);
  }

  getProfileImage(): string {
    return this.profileImage.getValue();
  }
  constructor() { }
}
