import { objectEntries, shallowClone, isNumber, assign, find, getType, isMatchOption, matchList, TraceContextInjection } from '@datadog/browser-core';
import { getCrypto } from '../../browser/crypto';
import { createSpanIdentifier, createTraceIdentifier, toPaddedHexadecimalString } from './identifier';
import { isTraceSampled } from './sampler';
export function isTracingOption(item) {
  var expectedItem = item;
  return getType(expectedItem) === 'object' && isMatchOption(expectedItem.match) && Array.isArray(expectedItem.propagatorTypes);
}
/**
 * Clear tracing information to avoid incomplete traces. Ideally, we should do it when the
 * request did not reach the server, but the browser does not expose this. So, we clear tracing
 * information if the request ended with status 0 without being aborted by the application.
 *
 * Reasoning:
 *
 * * Applications are usually aborting requests after a bit of time, for example when the user is
 * typing (autocompletion) or navigating away (in a SPA). With a performant device and good
 * network conditions, the request is likely to reach the server before being canceled.
 *
 * * Requests aborted otherwise (ex: lack of internet, CORS issue, blocked by a privacy extension)
 * are likely to finish quickly and without reaching the server.
 *
 * Of course, it might not be the case every time, but it should limit having incomplete traces a
 * bit.
 * */
export function clearTracingIfNeeded(context) {
  if (context.status === 0 && !context.isAborted) {
    context.traceId = undefined;
    context.spanId = undefined;
    context.traceSampled = undefined;
  }
}
export function startTracer(configuration, sessionManager) {
  return {
    clearTracingIfNeeded: clearTracingIfNeeded,
    traceFetch: function (context) {
      return injectHeadersIfTracingAllowed(configuration, context, sessionManager, function (tracingHeaders) {
        var _a;
        if (context.input instanceof Request && !((_a = context.init) === null || _a === void 0 ? void 0 : _a.headers)) {
          context.input = new Request(context.input);
          Object.keys(tracingHeaders).forEach(function (key) {
            ;
            context.input.headers.append(key, tracingHeaders[key]);
          });
        } else {
          context.init = shallowClone(context.init);
          var headers_1 = [];
          if (context.init.headers instanceof Headers) {
            context.init.headers.forEach(function (value, key) {
              headers_1.push([key, value]);
            });
          } else if (Array.isArray(context.init.headers)) {
            context.init.headers.forEach(function (header) {
              headers_1.push(header);
            });
          } else if (context.init.headers) {
            Object.keys(context.init.headers).forEach(function (key) {
              headers_1.push([key, context.init.headers[key]]);
            });
          }
          context.init.headers = headers_1.concat(objectEntries(tracingHeaders));
        }
      });
    },
    traceXhr: function (context, xhr) {
      return injectHeadersIfTracingAllowed(configuration, context, sessionManager, function (tracingHeaders) {
        Object.keys(tracingHeaders).forEach(function (name) {
          xhr.setRequestHeader(name, tracingHeaders[name]);
        });
      });
    }
  };
}
function injectHeadersIfTracingAllowed(configuration, context, sessionManager, inject) {
  if (!isTracingSupported() || !sessionManager.findTrackedSession()) {
    return;
  }
  var tracingOption = find(configuration.allowedTracingUrls, function (tracingOption) {
    return matchList([tracingOption.match], context.url, true);
  });
  if (!tracingOption) {
    return;
  }
  var traceId = createTraceIdentifier();
  context.traceSampled = !isNumber(configuration.traceSampleRate) || isTraceSampled(traceId, configuration.traceSampleRate);
  if (!context.traceSampled && configuration.traceContextInjection !== TraceContextInjection.ALL) {
    return;
  }
  context.traceId = traceId;
  context.spanId = createSpanIdentifier();
  inject(makeTracingHeaders(context.traceId, context.spanId, context.traceSampled, tracingOption.propagatorTypes));
}
export function isTracingSupported() {
  return getCrypto() !== undefined;
}
/**
 * When trace is not sampled, set priority to '0' instead of not adding the tracing headers
 * to prepare the implementation for sampling delegation.
 */
function makeTracingHeaders(traceId, spanId, traceSampled, propagatorTypes) {
  var tracingHeaders = {};
  propagatorTypes.forEach(function (propagatorType) {
    switch (propagatorType) {
      case 'datadog':
        {
          assign(tracingHeaders, {
            'x-datadog-origin': 'rum',
            'x-datadog-parent-id': spanId.toString(),
            'x-datadog-sampling-priority': traceSampled ? '1' : '0',
            'x-datadog-trace-id': traceId.toString()
          });
          break;
        }
      // https://www.w3.org/TR/trace-context/
      case 'tracecontext':
        {
          assign(tracingHeaders, {
            traceparent: "00-0000000000000000".concat(toPaddedHexadecimalString(traceId), "-").concat(toPaddedHexadecimalString(spanId), "-0").concat(traceSampled ? '1' : '0')
          });
          break;
        }
      // https://github.com/openzipkin/b3-propagation
      case 'b3':
        {
          assign(tracingHeaders, {
            b3: "".concat(toPaddedHexadecimalString(traceId), "-").concat(toPaddedHexadecimalString(spanId), "-").concat(traceSampled ? '1' : '0')
          });
          break;
        }
      case 'b3multi':
        {
          assign(tracingHeaders, {
            'X-B3-TraceId': toPaddedHexadecimalString(traceId),
            'X-B3-SpanId': toPaddedHexadecimalString(spanId),
            'X-B3-Sampled': traceSampled ? '1' : '0'
          });
          break;
        }
    }
  });
  return tracingHeaders;
}
