<header class="header" [ngClass]="{ 'no-internet': !isOnline }">
    <div class="head-nav">
        <div class="hd hd--left">
            <div class="menu-icon" (click)="togglePopup()" #menuIcon>
                <img alt="menu" class="menu" src="./assets/images/menu.png"/>
            </div>
            <div class="logo">
                <img alt="MyLogo" class="myPrxlogo" src="./assets/images/MyLogo.svg"/>
            </div> 
        </div>
        <div class="hd hd--right">
            <a (click)="openSPC()" class="ntf-icon" *ngIf="(currentPath === 'spc') ? false : true && isSecurePatientComm">
                <svg width="52" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.28564 26.1484V8.14844C2.28564 7.59844 2.48148 7.1276 2.87314 6.73594C3.26481 6.34427 3.73564 6.14844 4.28564 6.14844H20.2856C20.8356 6.14844 21.3065 6.34427 21.6981 6.73594C22.0898 7.1276 22.2856 7.59844 22.2856 8.14844V20.1484C22.2856 20.6984 22.0898 21.1693 21.6981 21.5609C21.3065 21.9526 20.8356 22.1484 20.2856 22.1484H6.28564L2.28564 26.1484Z" fill="#7A27C9"/>
                    <rect x="11.7856" y="0.648438" width="25" height="13" rx="6.5" fill="#FE0181"/>
                    <rect x="11.7856" y="0.648438" width="25" height="13" rx="6.5" stroke="white"/>
                    <text id="unreadMsgCounts" x="25" y="11" font-size="10" fill="#FFF" text-anchor="middle">
                        {{ unreadMsgCounts }}
                    </text>
                </svg>         
            </a>
            <a><img alt="MyLogo" class="bell-icon" src="./assets/images/bell.png "/></a>
            <a (click)="showSection('profile')"><img alt="bell" class="bell-icon border_radius_50" [src]="avatarUrl"/></a>
        </div>
    </div>
</header> 
<div class="backdrop" #backdrop></div>
<div id="popup-menu" class="popup-menu space-y-4" #popupMenu #breadcrumbs>
    <a (click)="showSection('dashboard')">
        <span class="material-symbols-outlined">home</span>
        <span>Home</span>
    </a>
    <a (click)="showSection('pendingPayments')"> 
        <span class="material-symbols-outlined">credit_card</span>
        <span>Pending Payments</span>
    </a>
    <a (click)="showSection('myMeds')">
        <span class="material-symbols-outlined">history</span>
        <span>My Meds</span>
    </a>
    <a (click)="showSection('readyToDispense')">
        <span class="material-symbols-outlined">package</span>
        <span>Ready to Dispense</span>
    </a>
    <a (click)="showSection('newPrescription')">
        <span class="material-symbols-outlined">prescriptions</span>
        <span>New Prescription</span>
    </a>
    <a (click)="showSection('refillsDue')">
        <span class="material-symbols-outlined">medication</span>
        <span>Refills Due</span>
    </a>
    <a (click)="showSection('profile')">
        <span class="material-symbols-outlined">account_circle</span>
        <span>Profile</span>
    </a>
    <a href="#" (click)="logout()">
        <span class="material-symbols-outlined">logout</span>
        <span>Log out</span>
    </a>
</div>